

.mainSection {
    // width: 750px;
    // height: 750px;
    // border-radius:  20px;
    // background-color: #141313;
    // -webkit-border-radius:  20px;
    // -moz-border-radius:  20px;
    // -ms-border-radius:  20px;
    // -o-border-radius:  20px;
    // box-shadow: 0 0 10px rgba(0,0,0,0.5);
        h2{
            text-align: center;
        }
        h3{
            text-align: center;
            font-size: 40px;
            font-weight: 700;
        }
        img{
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        p{
            text-align: center;
        }
}

